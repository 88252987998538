import TariffQuote from "../../models/TariffQuote.js"
import TariffQuoteV2 from "../../models/TariffQuoteV2.js"
import { Fuel, MeterType, usageType } from "./QuoteFormConstants.js"
import ErrorReporter from "../ErrorReporter.js"
import GetQuoteApi from "./GetQuote.js"
import GetQuoteApiV2 from "./GetQuoteV2.js"

const NIGHT_DAY_RATIO_DEFAULT = 42
const getFuelTypeRaw = fuelType => {
  if (fuelType === Fuel.ElecAndGas) return "Dual Fuel"
  if (fuelType === Fuel.Electricity) return "Electricity only"
  throw new Error("Invalid fuel type")
}

const getFuelTypeRawV2 = fuelType => {
  if (fuelType === Fuel.ElecAndGas) return "DUAL_FUEL"
  if (fuelType === Fuel.Electricity) return "ELECTRICITY_ONLY"
  if (fuelType === Fuel.Gas) return "GAS_ONLY"

  throw new Error("Invalid fuel type")
}

const getUserType = userType => {
  if (!userType) return ""

  const validTypes = ["low", "medium", "high"]
  if (validTypes.includes(userType)) return userType
  throw new Error("Invalid user type")
}

const getUserTypeV2 = userType => {
  if (!userType) return ""

  if (userType === usageType.low) return "LOW"
  if (userType === usageType.medium) return "MEDIUM"
  if (userType === usageType.high) return "HIGH"

  const validEvTypes = ["LOW", "MEDIUM", "HIGH", "EXACT_AMOUNT"]
  if (validEvTypes.includes(userType)) return userType
  throw new Error("Invalid user type")
}

const getUsage = quoteForm => {
  const gasUsage =
    quoteForm.exactAmountGas.period === "year"
      ? quoteForm.exactAmountGas.usage
      : String(Number(quoteForm.exactAmountGas.usage) * 12)
  const electricityUsage =
    quoteForm.exactAmountElec.period === "year"
      ? quoteForm.exactAmountElec.usage
      : String(Number(quoteForm.exactAmountElec.usage) * 12)

  return {
    gasUsage,
    electricityUsage
  }
}

const parseQuoteInputData = quoteForm => {
  const isMeterMultirate = quoteForm.isMeterMultirate

  let rawQuoteForm = {
    fuel_type: getFuelTypeRaw(quoteForm.fuel),
    user_type: getUserType(quoteForm.estimatedUsage),
    smart_meter: quoteForm.meterType === MeterType.Smart,
    economy_seven_meter: !!isMeterMultirate,
    // Defaults for estimated usage
    fuel_usage_in: "",
    estimate_type: "basic",
    electricity_interval: "",
    gas_interval: "",
    electricity_usage: "",
    gas_usage: "",
    night_day_usage: NIGHT_DAY_RATIO_DEFAULT,
    // Defaults
    payment: "Monthly Direct Debit",
    seasonal: "seasonal_adjustment",
    // only apply for detailed estimations, which is not currently used
    number_of_bedrooms: "",
    heat_source: "",
    cooking_source: "",
    supplier_private: true,
    suppliers_differ: false
  }

  if (!quoteForm.estimatedUsage) {
    const usage = getUsage(quoteForm)

    rawQuoteForm = {
      ...rawQuoteForm,
      fuel_usage_in: "kwh",
      estimate_type: "",
      electricity_interval: "year",
      gas_interval: "year",
      electricity_usage: usage.electricityUsage,
      gas_usage: quoteForm.fuel === Fuel.ElecAndGas ? usage.gasUsage : ""
    }
  }

  return {
    postcode: quoteForm.postcode,
    request: rawQuoteForm
  }
}

const parseQuoteInputDataV2 = quoteForm => {
  const isMeterMultirate = quoteForm.isMeterMultirate

  let rawQuoteForm = {
    postcode: quoteForm.postcode,
    hasEconomySevenMeter: !!isMeterMultirate,
    estimatedUsageType: getUserTypeV2(quoteForm.estimatedUsage),
    fuelType: getFuelTypeRawV2(quoteForm.fuel),
    electricityUsagePerYearInKwh: "",
    gasUsagePerYearInKwh: "",
    generateQuotesForEvTariffs: quoteForm.hasEv
  }

  if (!quoteForm.estimatedUsage) {
    const usage = getUsage(quoteForm)

    rawQuoteForm = {
      ...rawQuoteForm,
      estimatedUsageType: "EXACT_AMOUNT",
      electricityUsagePerYearInKwh: usage.electricityUsage,
      gasUsagePerYearInKwh:
        quoteForm.fuel === Fuel.ElecAndGas ? usage.gasUsage : ""
    }
  }

  return rawQuoteForm
}

const isValidQuote = (rawQuote, quoteInputData) => {
  const isElecRateValid = quoteInputData.isMeterMultirate
    ? rawQuote.electricity_day_pence_per_kw !== "0.00" &&
      rawQuote.electricity_night_pence_per_kw !== "0.00"
    : rawQuote.electricity_pence_per_kw !== "0.00"

  return (
    !(
      rawQuote.electricity_product_code === "" ||
      rawQuote.electricity_standing_charge_pence_per_day === "0.00" ||
      !isElecRateValid
    ) &&
    !(
      (rawQuote.gas_product_code === "" ||
        rawQuote.gas_standing_charge_pence_per_day === "0.00" ||
        rawQuote.gas_pence_per_kw === "0.00") &&
      quoteInputData.fuel === Fuel.ElecAndGas
    )
  )
}

const isFixedTariff = tariff => !!tariff.tariff_length

const getFixedTariffName = rawQuotes => {
  const fixedTariff = rawQuotes.data.find(tariff => isFixedTariff(tariff))
  if (fixedTariff) return fixedTariff.short_tariff_name.split("/")[0]
}

export default {
  async getQuotes(quoteInputData) {
    const parsedInputData = parseQuoteInputData(quoteInputData)
    const rawQuotes = await GetQuoteApi.getQuote(parsedInputData)

    const validRawQuotes = rawQuotes.data.filter(rawQuote => {
      const isValid = isValidQuote(rawQuote, quoteInputData)
      if (!isValid) {
        ErrorReporter.report(new Error("Invalid enrolment quote"), {
          quoteRequest: parsedInputData,
          quote: rawQuote
        })
      }
      return isValid
    })

    const noValidFixedTariffQuote = !validRawQuotes.some(tariff =>
      isFixedTariff(tariff)
    )
    if (validRawQuotes.length) {
      validRawQuotes.sort(
        (quote1, quote2) =>
          quote1.pounds_cost_per_year - quote2.pounds_cost_per_year
      )
      validRawQuotes[0].is_recommended = true
    }

    return {
      quotes: validRawQuotes.map(rawQuote => new TariffQuote(rawQuote)),
      invalidQuoteTariffName: noValidFixedTariffQuote 
        ? getFixedTariffName(rawQuotes) 
        : null
    }
  },
  async getQuotesV2(quoteInputData) {
    const parsedInputData = parseQuoteInputDataV2(quoteInputData)
    const rawQuotes = await GetQuoteApiV2.getQuote(parsedInputData)

    const validRawQuotes = rawQuotes.data.filter(rawQuote => {
      const isValid = isValidQuote(rawQuote, quoteInputData) 
      if (!isValid) {
        ErrorReporter.report(new Error("Invalid enrolment quote"), {
          quoteRequest: parsedInputData,
          quote: rawQuote
        })
      }
      return isValid
    })

    if (validRawQuotes.length) {
      validRawQuotes.sort(
        (quote1, quote2) =>
          quote1.costPerYearInPounds - quote2.costPerYearInPounds
      )
      validRawQuotes[0].isRecommended = true
    }

    return {
      quotes: validRawQuotes.map(rawQuote => new TariffQuoteV2(rawQuote))
    }
  }
}

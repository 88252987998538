import axios from "axios"
import clearCacheInterceptor from "@soenergy/frontend-library/src/services/http/responseErrorInterceptors/clearCacheInterceptor"

const apiClient = () => {
  const baseURL = process.env.VUE_APP_API_GATEWAY_URL

  const api = axios.create({
    baseURL,
  })

  api.interceptors.response.use(clearCacheInterceptor)

  return api
}

export default {
  switchCancellationReasons(details) {
    return apiClient().post("customers/cancellation-reason", details, {
      withCredentials: false,
    })
  },
  getCurrentPaymentPlan(accountId) {
    return apiClient().get(`payment-plans/current?account_number=${accountId}`)
  },

  getPaymentPlanEligibility(accountId) {
    return apiClient().get(
      `payment-plans/customer-eligibility?account_number=${accountId}`
    )
  },
  createBooking(accountId, payload) {
    return apiClient().post(`smart-meter/${accountId}/create-booking`, payload)
  },
  getSmbpAvailableSlots(postcode) {
    return apiClient().get(`smart-meter/available-slots?postcode=${postcode}`)
  },
  unsubscribe(accountId) {
    return apiClient().post(`/smart-meter/${accountId}/unsubscribe`)
  },
  getBookings(juniferAccountId) {
    return apiClient().get(`smart-meter/${juniferAccountId}/get-bookings`)
  },
  getConfiguration(configurationName) {
    return apiClient().get(`/api/v1/configurations/${configurationName}`)
  },
}

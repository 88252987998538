/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApp, nextTick, h, Fragment } from "vue"
import App from "./App.vue"
import store from "./store/store"
import router from "./router"
import EventBus from "./methods/eventBus"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { localStore } from "@soenergy/frontend-library/src/services/StorageFactory"
import { runOnceCookieIsAllowed } from "@soenergy/frontend-library/src/services/CookiePreferences"
import PersistentStoreService, {
  ClassSerializerFactory,
} from "@soenergy/frontend-library/src/services/PersistentStoreService"
import DayJSSerializer from "@soenergy/frontend-library/src/services/PersistentStoreService/DayJSSerializer.js"
import StoryblokHelperPlugin from "@soenergy/frontend-library/src/plugins/storyblokHelper"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import UserStatusService from "@soenergy/frontend-library/src/services/UserStatusService"
import WHDEligibilityService from "@soenergy/frontend-library/src/services/WHDEligibilityService"
import BreakpointsPlugin from "@soenergy/frontend-library/src/plugins/breakpoints/breakpointsV2"
import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"
import AnalyticsPlugin, {
  AnalyticsService,
  MixpanelTracker,
} from "@soenergy/frontend-library/src/plugins/analytics"
import * as CookieConsentPlugin from "@soenergy/frontend-library/src/services/CookieConsentPlugin"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"
import "@soenergy/frontend-library/src/stylesheets/vendors/bulma.scss"
import "@soenergy/frontend-library/src/stylesheets/base.scss"
import "@soenergy/frontend-library/src/stylesheets/components/_form.scss"
import AccountAnalytics from "./services/AccountAnalytics"
import CotMoveOut from "@/models/CotMoveOut"
import { initDatadogPlugin } from "@soenergy/frontend-library/src/services/datadog"
import initDatadogIdentity from "@/methods/initDatadogIdentity"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

console.log(process.env.NODE_ENV)

dayjs.extend(duration)
window.ErrorReporter = ErrorReporter

let app
if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const VueAxe = require("vue-axe")
  app = createApp({
    render: () => h(Fragment, [h(App), h(VueAxe.VueAxePopup)]),
  })
  app.use(VueAxe.default)
} else {
  app = createApp(App)
}
app.config.globalProperties.$localStorage = localStore
app.use(initDatadogPlugin())
app.use(store)
app.use(router)
app.use(StoryblokHelperPlugin)
app.use(BreakpointsPlugin)

AuthenticationService.init({
  store,
  onSessionExpired: () => {
    store.dispatch("logout")
  },
})
UserStatusService.init({ store })
WHDEligibilityService.init({ store })
if (process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true") {
  CookieConsentPlugin.init()
}

PersistentStoreService.init({
  store,
  serializers: [
    DayJSSerializer,
    ClassSerializerFactory(CotMoveOut, "CotMoveOut"),
  ],
  reducer: (state) => ({
    juniferDataRefreshTime: state.juniferDataRefreshTime,
    currentAccount: state.currentAccount,
    help: state.help,
    junifer: state.junifer,
    userStatus: state.userStatus,
    user: state.user,
    bills: state.bills,
    gengame: state.gengame,
    usage: state.usage,
    home: state.home,
    psr: state.psr,
    whdRegionEligibility: state.whdRegionEligibility,
    meters: state.meters,
    cotMoveOut: state.cotMoveOut,
    smbp: state.smbp,
  }),
  expirationTimeInSeconds: dayjs
    .duration(
      process.env.VUE_APP_BLOB_REFRESH_AMOUNT as any,
      process.env.VUE_APP_BLOB_REFRESH_UNIT as any
    )
    .asSeconds(),
  allowPrimitiveToObject: process.env.VUE_APP_USE_NEXUS_HOME_PAGE === "true",
})

app.use(AnalyticsPlugin)

runOnceCookieIsAllowed("performance", () => {
  MixpanelService.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
    projectName: PROJECTS.AccountApp,
  })
  AnalyticsService.init({
    trackers: [MixpanelTracker],
    vueRouter: router,
    projectName: PROJECTS.AccountApp,
    setGlobalLinkTracking: true,
    nextTick,
  })
})

app.mixin({
  data: function () {
    return {
      get feature() {
        return {
          usage: false,
          payments: false,
          offers: false,
          detachedItems: !this.production,
        }
      },
      get production() {
        return process.env.TARGET_ENV === "production"
      },
      get local() {
        return process.env.TARGET_ENV === "development"
      },
    }
  },
})

app.directive("autofocus", {
  mounted: function (el, binding) {
    if (binding.value !== false) {
      const targetElement =
        el.tagName === "INPUT" ? el : el.querySelector("input")
      targetElement.focus()
    }
  },
})

app.mount("#app")

EventBus.subscribe("goToLogout", () => {
  if (router.currentRoute.value.meta.requiresAuth) {
    router.push("logout")
  }
})

EventBus.subscribe("userDataLoaded", () => {
  AccountAnalytics.registerUserProperties()

  runOnceCookieIsAllowed("performance", () => {
    initDatadogIdentity()
  })
})
EventBus.subscribe("goToNetworkErrorPage", () => {
  router.push("network-error")
})

if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
}

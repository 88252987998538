import { reportErrorToDataDog, logInfoToDataDog } from "./datadog"

export default {
  /**
   * Reports error to Datadog
   * for backwards-compatibility and in future non-critical logs, should be tracked in a different way.
   * @param {Error} error - error that is to be logged
   * @param {object} param - object with custom fields of choice
   */
  report: (error, params = {}, { severity, context, groupingHash } = {}) => {
    reportErrorToDataDog(error, params)
  },
  /**
   * Logs information to Datadog
   * @param {string} description - description of information that is to be logged
   * @param {object} metadata - object with custom fields of choice
   */
  log: (description, metadata) => {
    logInfoToDataDog(description, metadata)
  }
}

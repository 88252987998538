import axios from "axios"
import EventBus from "@/methods/eventBus"
import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import authenticate from "@soenergy/frontend-library/src/services/http/requestInterceptors/authenticate"
import clearCacheInterceptor from "@soenergy/frontend-library/src/services/http/responseErrorInterceptors/clearCacheInterceptor"

const redirectToLogin = (error) => {
  let path
  if (error.response && error.response.config) {
    const urlArray = error.response.config.url.split("/")
    path = urlArray[urlArray.length - 1]
  }

  if (error.response && error.response.status == 401 && path !== "login") {
    // trigger redirection to login
    EventBus.emit("goToLogout")
  }
}
const responseErrorInterceptor = (error) =>
  redirectToLogin(error) || Promise.reject(error)

const baseURL = process.env.VUE_APP_ACCOUNT_SITE_URL
const apiClient = HttpClient({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL,
  requestInterceptors: [authenticate],
  responseInterceptors: [clearCacheInterceptor],
  responseErrorInterceptors: [responseErrorInterceptor],
})

export default {
  async fetchCotMoveOut(accountId) {
    return apiClient.get(`api/v1/accounts/${accountId}/change_of_tenancy`)
  },
  fetchCotMoveWithUs() {
    return apiClient.get(`api/v1/accounts/change_of_tenancy/move_with_us`)
  },
  cancelMoveWithUs() {
    return apiClient.delete(`api/v1/accounts/change_of_tenancy/move_with_us`)
  },
  createPassword(data) {
    return apiClient.post("api/v1/auth/create_password", data)
  },
  passwordReset(data) {
    return apiClient.post("api/v1/auth/password", data)
  },
  confirmEmailChange(data) {
    return apiClient.patch("api/v1/user/confirm-email-update", data)
  },
  emailChange(newEmail) {
    return apiClient.patch(`api/v1/user/email?new_email=${newEmail}`)
  },
  addPhoneNumber(data) {
    return apiClient.post("api/v1/customer/add_phone_number", data)
  },
  sendActivationEmail(email) {
    return apiClient.post("api/v1/user/send_activation_email", { email })
  },
  fetchAssets() {
    return apiClient.get("api/v1/assets.json", {
      skipAuthentication: true,
    })
  },
  fetchJunifer() {
    return apiClient.get("api/v1/junifer.json")
  },
  refreshJunifer() {
    return apiClient.patch("api/v1/junifer.json")
  },
  refreshJuniferCacheForAccount(accountId) {
    return apiClient.patch(`/api/v1/junifer/${accountId}/`)
  },
  setCurrentAccount(accountId) {
    return apiClient.patch("/api/v1/user", { account_id: accountId })
  },
  getJuniferCacheRefreshStatus() {
    return apiClient.get("api/v1/user/sync_status")
  },
  getJuniferAccountCacheRefreshStatus(accountId) {
    return apiClient.get(`api/v1/accounts/${accountId}/sync_status`)
  },
  fetchWebsite() {
    return apiClient.get("api/v1/user.json")
  },
  fetchBills(accountId) {
    return apiClient.get(`api/v1/accounts/${accountId}/bills`)
  },
  fetchBillLine(billId) {
    return apiClient.get(`api/v1/bills/${billId}/bill_lines`)
  },
  getProduct(params) {
    return apiClient.get("api/v1/products", {
      params,
    })
  },
  getTariffs(params) {
    return apiClient.get("api/v1/tariffs", {
      params,
    })
  },
  createTicket(ticketDetails) {
    const { account_id } = ticketDetails

    return apiClient.post(
      `api/v1/accounts/${account_id}/tickets.json`,
      ticketDetails
    )
  },
  getSmartMeterInterest() {
    return apiClient.get("api/v1/customer/smart-meter-interest")
  },
  setSmartMeterInterest(smartMeterOptIn) {
    return apiClient.patch(
      `/api/v1/customer/update-smart-meter-interest?status=${smartMeterOptIn}`
    )
  },
  getMeterReadReminder(accountId) {
    return apiClient.get(`/api/v1/accounts/${accountId}/meter_read_reminder`)
  },
  createMeterReadReminder(accountId, data) {
    return apiClient.post(
      `/api/v1/accounts/${accountId}/meter_read_reminder`,
      data
    )
  },
  updateMeterReadReminder(accountId, data) {
    return apiClient.patch(
      `/api/v1/accounts/${accountId}/meter_read_reminder`,
      data
    )
  },
  async postRead(reads) {
    return apiClient.post("api/v1/readings.json", {
      meters: reads.map((read) => {
        const rawRead = {
          digits: read.digits,
          unit_type: read.type,
          meter_identifier: read.meterIdentifier || null,
          meterpoint_id: String(read.endpointID),
          units: Number(read.digits.join("")),
          register_identifier: read.identifier,
        }
        if (read.readingDate) {
          rawRead.reading_date = read.readingDate.format("YYYY-MM-DD")
        }
        return rawRead
      }),
    })
  },
  getReadings() {
    return apiClient.get(`/api/v1/readings`)
  },
  getDirectDebit(accountId) {
    return apiClient.get(
      `api/v1/payment-method/details?account_id=${accountId}`
    )
  },
  validateDirectDebit(data) {
    return apiClient.post("api/v1/payment-method/verify", data)
  },
  newDirectDebit(data) {
    return apiClient.post(
      `/api/v1/accounts/${data.account_id}/direct_debit`,
      data
    )
  },
  viewDirectDebitMandate(accountId) {
    return apiClient
      .get(`/api/v1/payment-method/mandate_pdf?account_id=${accountId}`)
      .then((response) => {
        const base64ToUint8Array = (base64) => {
          const binaryString = window.atob(base64)
          const len = binaryString.length
          const bytes = new Uint8Array(len)
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i)
          }
          return bytes
        }

        const downloadBlob = (blob, fileName) => {
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          link.remove()
        }

        const binaryData = base64ToUint8Array(response.data.pdf_bytes) // Decoding the Base64 string
        const blob = new Blob([binaryData], { type: "application/pdf" })
        downloadBlob(blob, "direct_debit_mandate.pdf")
      })
  },
  makeFrictionlessPayment(accountId, data) {
    return apiClient.post(`api/v1/payment/${accountId}`, data, {
      withCredentials: true,
    })
  },
  makeChallengedPayment(accountId, data) {
    return apiClient.post(
      `api/v1/payment/${accountId}/challenge-complete`,
      data,
      {
        withCredentials: true,
      }
    )
  },
  getPaymentJwt(accountId) {
    return apiClient.get(`api/v1/payment/${accountId}/jwt`)
  },
  getStatement(id) {
    return apiClient.get(`api/v1/bills/${id}/download.json`)
  },
  setMarketingPreferences(marketingOptIn) {
    return apiClient.patch(`/api/v1/marketing.json`, {
      marketing_opt_in: marketingOptIn,
    })
  },
  renewContract(data) {
    return apiClient.post(
      `/api/v1/accounts/${data.account_id}/renewals.json`,
      data
    )
  },
  cotMoveOut(data, accountId) {
    return apiClient.post(
      `/api/v1/accounts/${accountId}/change_of_tenancy`,
      data
    )
  },
  getFeaturesStates() {
    return apiClient.get("api/v1/features", {
      skipAuthentication: true,
    })
  },
  reportBug(formData) {
    return axios({
      method: "POST",
      url: baseURL + "/api/v1/report-problem",
      data: formData,
      skipAuthentication: true,
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: `Bearer ${AuthenticationService.getAccessToken()}`,
      },
    })
  },
  switchCancellation(data) {
    return apiClient.post("/api/v1/cancellation", {
      account_id: data.accountId,
      meterpoint_id: data.meterpointId,
      reason: data.reason,
    })
  },
  updatePaymentSchedule(accountId, data) {
    return apiClient.post(
      `/api/v1/accounts/${accountId}/payment_schedule`,
      data
    )
  },
  getSuggestedPayment(accountId) {
    return apiClient.get(
      `/api/v1/accounts/${accountId}/fetch_suggested_payment`
    )
  },
  getEstimatedAnnualUsage(meterpointId) {
    return apiClient.get(`/api/v1/meterpoints/${meterpointId}/estimated_usage`)
  },
  getVulnerabilityOptions() {
    return apiClient.get(`/api/v1/vulnerabilities/index`)
  },
  getVulnerabilities() {
    return apiClient.get(`/api/v1/vulnerabilities`)
  },
  sendPSREmail(data) {
    return apiClient.post(`/api/v1/vulnerabilities/email`, data)
  },
  setVulnerability(data) {
    return apiClient.post(`/api/v1/vulnerabilities`, data)
  },
  getReadLoginData() {
    return apiClient.get(`/api/v1/light_login`)
  },
  getReadFrequency(meterpoints) {
    return apiClient.get(
      `/smart-meter/meter-point/meter-reading-frequency?mpxns=${meterpoints}`
    )
  },
  updateReadFrequency(data) {
    return apiClient.put(
      `/smart-meter/meter-point/meter-reading-frequency`,
      data
    )
  },
  sendActivityNote(accountId, data) {
    return apiClient.post(`/api/v1/accounts/${accountId}/notes`, data)
  },
  getOutstandingComplaintsCount(accountId) {
    return apiClient.get(
      `/api/v1/accounts/${accountId}/outstanding-complaints/total`
    )
  },
  getMeterReadStatus(meterpointId) {
    return apiClient.get(
      `/api/v1/readings_status?meter_point_id=${meterpointId}`
    )
  },
  getLastMeterReadStatus(meterpointId) {
    return apiClient.get(
      `/api/v1/last_reading_status?meter_point_id=${meterpointId}`
    )
  },
  cotMoveWithUs(payload) {
    return apiClient.post(
      "/api/v1/accounts/change_of_tenancy/move_with_us",
      payload
    )
  },
  getUsageProfileForecast(accountId) {
    return apiClient.get(`/api/v1/forecast/${accountId}/balance`)
  },
  getSmartMeterEligibility(juniferAccountId) {
    return apiClient.get(`/smart-meter/eligibility/${juniferAccountId}`)
  },
}

// // Please bear in mind these constants are used for tracking as values of Mixpanel super property
export const Fuel = Object.freeze({
  Electricity: "Electricity",
  ElecAndGas: "ElecAndGas",
  Gas: "Gas"
})

export const MeterType = Object.freeze({
  Standard: "Standard",
  Smart: "Smart",
  Economy7: "Economy7"
})

export const ExactAmount = Object.freeze({
  ExactAmount: "ExactAmount"
})

export const getDisplayNames = isDesktop => ({
  fuel: {
    [Fuel.Electricity]: "Electricity",
    [Fuel.ElecAndGas]: "Gas & electricity",
    [Fuel.Gas]: "Gas"
  },
  meterType: {
    [MeterType.Smart]: isDesktop ? "Smart meter" : "Smart",
    [MeterType.Standard]: isDesktop ? "Standard meter" : "Standard"
  },
  exactAmount: {
    [ExactAmount.ExactAmount]: isDesktop ? "Exact amount" : "Exact"
  }
})

export const usageEnergyType = {
  Electricity: "electricity",
  Gas: "gas"
}

export const usageProfilesOfgem = {
  low: {
    gasUsage: 7500,
    electricityUsage: 1800,
    electricityDayUsage: 1276,
    electricityNightUsage: 924
  },
  medium: {
    gasUsage: 11500,
    electricityUsage: 2700,
    electricityDayUsage: 2262,
    electricityNightUsage: 1638,
    electricityEvUsage: 5000
  },
  high: {
    gasUsage: 17000,
    electricityUsage: 4100,
    electricityDayUsage: 3886,
    electricityNightUsage: 2814
  }
}

export const usageType = Object.freeze({
  low: "low",
  medium: "medium",
  high: "high"
})

export const usageMinMax = {
  gas: {
    month: {
      min: 80,
      max: 6000
    },
    year: {
      min: 1000,
      max: 70000
    }
  },
  electricity: {
    month: {
      min: 16,
      max: 2500
    },
    year: {
      min: 200,
      max: 30000
    }
  }
}

export const usageMinEV = {
  month: 250,
  year: 3000
}

import dayjs from "dayjs"

class TariffQuoteV2 {
  constructor(rawQuote) {
    this.quoteId = String(rawQuote.quoteId)
    this.fullTariffName = rawQuote.tariffName
    this.tariffName = rawQuote.shortTariffName.split("/")[0]
    this.rate = rawQuote.rate
    this.durationMonths = rawQuote.tariffLengthInMonths
    this.paymentMethod =
      rawQuote.payment === "Monthly Direct Debit"
        ? "Direct Debit"
        : rawQuote.payment
    this.fuelType = rawQuote.fuelType

    this.annualCostValue = rawQuote.costPerYearInPounds
    this.monthlyCostValue = rawQuote.costPerMonthInPounds
    this.annualSavings = rawQuote.savingPerYearInPounds
    this.elecProductType = rawQuote.elecProductType // "Eco7", "Standard", "TOU"
    this.tariffType = rawQuote.tariffType // "Standard", "EV", "Green"

    this.elecRates = rawQuote.elecRates
    this.elecStandingCharge = rawQuote.elecStandingChargeInPence
    this.annualElecUsage = rawQuote.elecConsumptionPerYearInKwh

    this.gasUnitRate = rawQuote.gasCostPerKwhInPence
    this.gasStandingCharge = rawQuote.gasStandingChargeInPence
    this.annualGasUsage = rawQuote.gasConsumptionPerYearInKwh

    this.exitFee = rawQuote.earlyExitFeePerFuelInPounds
    this.startDate = dayjs(rawQuote.supplyStartDate)
    this.firstDdDate = rawQuote.firstDdDate
    this.otherDetails = rawQuote.otherDetails
    this.seasonalAdjustmentFactor = rawQuote.seasonalAdjustmentFactor / 100
    this.currentReferralFee = rawQuote.currentReferralFee
    this.elecProductCode = rawQuote.elecProductCode
    this.gasProductCode = rawQuote.gasProductCode
    this.isRecommended = rawQuote.isRecommended
  }

  get isVariable() {
    return !this.durationMonths
  }

  get isEco7() {
    return this.elecProductType === "Eco7"
  }

  get isTOU() {
    return this.elecProductType === "TOU"
  }

  monthlyCostSummer() {
    return this.getAdjustedMonthlyCost(1 - this.seasonalAdjustmentFactor)
  }

  monthlyCostWinter() {
    return this.getAdjustedMonthlyCost(1 + this.seasonalAdjustmentFactor)
  }

  monthlyCost() {
    return this.monthlyCostValue
  }

  annualCostAdjusted() {
    return this.annualCostValue
  }

  annualElecDayUsage() {
    if (!isEco7) return

    const dayRate = this.elecRates.find(item => item.name === "Day")
    return dayRate.elecConsumptionPerYearInKwh
  }

  annualElecNightUsage() {
    if (!isEco7) return

    const nightRate = this.elecRates.find(item => item.name === "Night")
    return nightRate.elecConsumptionPerYearInKwh
  }

  elecOffPeakRate() {
    return this.elecRates[1].costPerKwhInPence
  }

  get tariffDurationFormatted() {
    if (this.isVariable) return "Variable Tariff"

    if (this.durationMonths % 12 === 0) {
      const durationInYears = ["One", "Two", "Three", "Four", "Five", "Six"][
        Math.trunc(this.durationMonths / 12) - 1
      ]
      return `${durationInYears} Year Tariff`
    }
    return `${this.durationMonths} Month Tariff`
  }

  get endDate() {
    return this.durationMonths
      ? dayjs(this.startDate).add(this.durationMonths, "months")
      : null
  }

  getAdjustedMonthlyCost(adjustmentFactor) {
    const price = this.monthlyCost() * adjustmentFactor
    return price % 1 !== 0 ? price.toFixed(2) : String(price)
  }
}

export default TariffQuoteV2

import HttpClient from "../http/HttpClient"
import authenticate from "../../services/http/requestInterceptors/authenticate"
import clearCacheInterceptor from "../../services/http/responseErrorInterceptors/clearCacheInterceptor"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  responseInterceptors: [clearCacheInterceptor],
  requestInterceptors: [authenticate]
})

export default {
  requestPayment(data) {
    return apiClient.post(`payments`, data)
  },
  getPaymentDetailsByExternalId(externalId) {
    return apiClient.get(`payments/external-id/${externalId}`)
  },
  getPaymentDetailsBySessionId(ckoSessionId) {
    return apiClient.get(`payments/cko-session-id/${ckoSessionId}`)
  }
}

import axios from "axios"
import reportError from "../../services/http/responseErrorInterceptors/reportError"

const apiClient = () => {
  const baseURL = process.env.VUE_APP_API_GATEWAY_URL_V2
  const header = {
    "Content-Type": "application/json"
  }

  const api = axios.create({
    baseURL,
    "Cache-Control": "no-cache",
    headers: header
  })

  api.interceptors.response.use(
    response => response,
    error => reportError(error)
  )

  return api
}

export default {
  getQuote(data) {
    return apiClient().post("/api/v1/quotes", data, {
      withCredentials: true
    })
  },
}
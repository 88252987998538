import PersistentStoreService from "../../../services/PersistentStoreService"
import ErrorReporter from "../../../services/ErrorReporter"

export default response => {
  const isNexusHomeEnabled = process.env.VUE_APP_USE_NEXUS_HOME_PAGE === "true"
  if (!isNexusHomeEnabled) return response

  const method = response?.config?.method?.toLowerCase()
  const isWriteRequest =
    method && ["post", "patch", "put", "delete"].includes(method)

  if (isWriteRequest) {
    try {
      PersistentStoreService.disablePersistence()
      PersistentStoreService.clearStoredState()
    } catch (error) {
      ErrorReporter.report(error)
    }
  }

  return response
}
